.headerView {
  box-shadow: 1px 1px 5px 1px #e6e6e1 !important;
}

.contentView {
  overflow-y: auto;
}

.bottomView {
  /* box-shadow: 1px 1px 5px 1px #e6e6e1 !important; */
}

.billView {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.billText {
  color: darkred;
  font-family: GilroySemiBold !important;
  font-size: 15px !important;
  text-align: center;
}

.view1 {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}

.text1 {
  color: #555555;
  font-family: GilroyMedium !important;
  font-size: 10px !important;
  text-align: center;
}

.btnView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
}

.btnText {
  font-family: GilroyMedium !important;
  font-size: 15px !important;
  text-align: center;
}
