.loaderView {
  background-color: #fbfbfb;
}

.mainView {
  flex: 1;
  display: flex;
  flex-direction: column !important;
  height: 100%;
  background-color: #fbfbfb;
}

.topView {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderText {
  color: #000000;
  font-size: 30px !important;
  font-family: GilroyMedium !important;
  text-align: center;
}

.btnView {
}

.addMoreBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe342 !important;
  cursor: pointer;
  text-transform: capitalize !important;
  color: #000 !important;
  font-family: GilroyBold !important;
  font-size: 18px !important;
  border-radius: 40px !important;
  width: 100%;
  height: 60px !important;
  margin-bottom: 14px !important;
}

.payNow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000 !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-family: GilroyBold !important;
  font-size: 18px !important;
  border-radius: 40px !important;
  height: 60px !important;
  width: 100%;
}
