.topView {
  border-bottom: 1px solid #cccccc;
}

.topLeftView {
  flex: 1;
}

.topRightView {
  display: flex;
  align-items: center;
}

.text1 {
  font-family: GilroyMedium !important;
  font-size: 14px !important;
}

.text2 {
  font-family: GilroySemiBold !important;
  font-size: 14px !important;
}

.otpText {
  font-family: GilroyMedium !important;
  font-size: 14px !important;
}

.text3 {
  font-family: GilroySemiBold !important;
  font-size: 14px !important;
}
