.mainView {
  border: 1px solid #F2F2F3;
  border-radius: 16px;
  box-shadow: 0px 1px 3px 0px #00000040;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    linear-gradient(
      270deg,
      #F2F2F3 -37.9%,
      rgba(255, 255, 255, 0.37) 5.96%,
      rgba(255, 255, 255, 0) 111.15%
    );
}

.listView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listLabelView {
  flex: 1;
  display: flex;
}

.billTitle {
  color: #000000;
  font-size: 19px !important;
  font-family: GilroyBold !important;
}

.totalVal {
  color: #000000;
  font-family: GilroyBold !important;
}

.labelView {
  flex: 1;
  display: flex;
}

.labelText {
  color: #000000;
  font-size: 16px !important;
  font-family: GilroyMedium !important;
}

.billVal {
  color: #000000;
  font-family: GilroyMedium !important;
}
