.mainView {
    position: relative;
    position: relative;
    z-index: 2;
  }
  
  .blurImg {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(1px);
    position: absolute;
    border-radius: 17px;
    -webkit-filter: blur(1px);
    inset: 0.1px;
    z-index: 1;
    display: none !important;
  }
  
  .expandIcon {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid #ffe227;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
  
  .orderTitleMainView {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  
  .orderTitleView {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  
  
  .orderTitleText {
    color: #212529;
    font-size: 24px !important;
    line-height: 1 !important;
    font-family: Inter !important;
    font-weight: 600;
  }
  
  .orderNoText {
    color: #000000;
    font-size: 16px !important;
    font-family: GilroySemiBold !important;
  }
  
  .tableNoView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border: 1px solid #f7e574;
    border-radius: 50%;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4)
      ),
      linear-gradient(
        104.73deg,
        #ffe227 -37.9%,
        rgba(255, 226, 39, 0.37) 5.96%,
        rgba(255, 226, 39, 0) 111.15%
      );
  }
  
  .tableNoValue {
    color: #000000;
    font-size: 20px !important;
    line-height: 20px !important;
    font-family: GilroySemiBold !important;
  }
  
  .tableNoText {
    color: #000000;
    font-size: 10px !important;
    font-family: GilroySemiBold !important;
  }
  
  .cartItemListView {
    position: relative;
    z-index: 2;
  }
  
  .cartItemMainView {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .itemQtyView {
    min-width: 30px;
  }
  
  .itemQtyText {
    color: #6B6B6B;
    font-size: 15px !important;
    font-family: Inter !important;
    font-weight: 400;
  }
  
  .itemNameView {
    flex: 1;
  }
  
  .itemNameText {
    color: #000000;
    font-size: 15px !important;
    font-family: Inter !important;
    font-weight: 600;
  }
  
  .itemPriceView {
  }
  
  .itemPriceText {
    color: #000000;
    font-size: 15px !important;
    font-family: Inter !important;
    font-weight: 600;
  }
  