.mainView {
  display: flex;
  align-items: end;
}

.textInput {
  width: 100%;
}

.error {
  font-size: 11px !important;
  font-family: BauhausMedium !important;
  color: #af1414 !important;
  margin-bottom: -10px;
}
