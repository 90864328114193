.mainDialogView {
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 0.5px solid rgba(203, 219, 204, 1);
  top: 0;
}

.bgShade {
  position: absolute;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  backdrop-filter: blur(5px);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.contentView {
  position: relative;
  z-index: 2;
}
.desc1 {
  font-family: BauhausLight !important;
  font-size: 12px !important;
}
.desc2 {
  font-family: BauhausBold !important;
  font-size: 28px !important;
}

.desc3 {
  font-family: BauhausLight !important;
  font-size: 16px !important;
}

.restNameText {
  font-family: BauhausDemi !important;
  font-size: 16px !important;
}

.inputView {
  border-radius: 20px;
}

.btnMainView {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96%;
  height: 60px;
  border-radius: 30px;
}

.btnText {
  font-family: BauhausDemi !important;
  font-size: 18px !important;
}

.error {
  font-size: 11px !important;
  font-family: BauhausMedium !important;
  color: #af1414 !important;
  margin-top: 2px !important;
}
