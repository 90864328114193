.mainView {
}

.splitPaymentView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.subTitleText {
  color: #212529;
  font-size: 18px !important;
  font-family: GilroyBold !important;
}

.tipDescText {
  color: #373737;
  font-size: 13px !important;
  font-family: GilroyMedium !important;
}

.tipListMainView {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tipListView {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}

.tipListText {
  font-size: 20px !important;
  font-family: GilroyBold !important;
}

.paymentMethodMainView {
  background-color: #ffffff;
  border-radius: 15px;
}

.paymentMethodView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paymentMethod {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  cursor: pointer;
}

.addCreditDebitCardView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addCreditDebitCardText {
  color: #212529;
  font-size: 15px !important;
  font-family: GilroySemiBold !important;
}

.expiryDateText {
  color: #212529;
  font-size: 12px !important;
  font-family: GilroySemiBold !important;
}

.rememberCardView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rememberCardText {
  color: #212529;
  font-size: 14px !important;
  font-family: GilroyMedium !important;
  cursor: pointer;
}

.submitBtn {
  flex: 1;
  background-color: #000;
  color: #fff;
  font-family: GilroyBold !important;
  text-transform: capitalize;
  border-radius: 2px;
  margin-top: 2px;
  box-shadow: none;
  font-size: 14px !important;
  height: 50px;
}
