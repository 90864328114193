.main-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100%;
}

.main-sub-view {
  position: relative;
  background-color: #000000;
  width: var(--view-width);
  overflow: hidden;
}
